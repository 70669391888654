// @flow

import { Button, ButtonGroup } from '@blueprintjs/core';
import download from 'downloadjs';
import DownloadByDayButton from './download-by-day-button';
import { getColumnLabel } from '../../utils/metricsTableHelpers';
import { sanitiseString } from '../../utils/summaryPageHelpers';
import { Mobile, Desktop } from '../layouts/devices-sizes';
import { trackEvent, DOWNLOAD_CSV_BUTTON } from '../../services/mixpanel';

import type { breakdownByEnum } from '../../constants/graph-options';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as LocationModel from '../../models/location';
import * as ActiveMetricsModel from '../../models/active-metrics';
import * as QueryApiRequestModel from '../../models/query-api-request';
import * as QueryResponseModel from '../../models/query-response';
import styled from 'styled-components';
import DownloadByDayCSV from './download-by-day-csv';
type totalOnlyT = 'total_only';
export const BREAKDOWN_BY_DEFAULT: totalOnlyT = 'total_only';
const LOCATIONS_COLUMN = 'locations';
const DEFAULT_EMPTY_VALUE = '';

type Props = {
  period: QueryApiRequestModel.periodT,
  excludeStaff: boolean,
  excludeAgeAndGender: boolean,
  disabled: boolean,
  filename: string,
  activeMetrics: ActiveMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  filteredLocationIds: string[],
  locations: LocationModel.t[],
  breakdownBy: breakdownByEnum,
  availableMetrics: AvailableMetricsModel.t,
};

export type configHeaderT = {|
  metricGroupKey: string,
  metricKey: string,
  taxonomy?: string,
  name: string,
  isBreakdownColumn?: boolean,
|};

export type locationConfigT = {
  locationId: string,
  locationName: string,
};

const StyledButtonGroup = styled(ButtonGroup)`
  flex-wrap: wrap;
  gap: 0.5rem 0;
  justify-content: center;
`;

const getLocationConfigs = (
  locationIds: string[],
  locations: LocationModel.t[],
): locationConfigT[] => {
  return locationIds.map((locationId) => {
    const locationName = LocationModel.getLocationName(locationId, locations);
    const locationsConfig = {
      locationName,
      locationId,
    };
    return locationsConfig;
  });
};

const getHeadersConfig = (
  activeMetrics: ActiveMetricsModel.t,
  breakdownBy: breakdownByEnum,
  queryResponseList: QueryResponseListModel.t,
  availableMetrics: AvailableMetricsModel.t,
): configHeaderT[] => {
  const defaultHeaders = [
    { metricGroupKey: '', metricKey: '', name: LOCATIONS_COLUMN },
  ];

  if (breakdownBy !== BREAKDOWN_BY_DEFAULT) {
    defaultHeaders.push({
      metricGroupKey: '',
      metricKey: '',
      isBreakdownColumn: true,
      name: breakdownBy,
    });
  }

  const metricsHeaders = Object.entries(activeMetrics).reduce(
    (headersFinal, [metricGroupKey, activeMetricValue]) => {
      if (!activeMetricValue) return headersFinal;
      if (
        activeMetricValue.filters &&
        Array.isArray(activeMetricValue.filters)
      ) {
        activeMetricValue.filters.forEach((filter) => {
          if (
            activeMetricValue.metrics &&
            Array.isArray(activeMetricValue.metrics)
          ) {
            activeMetricValue.metrics.forEach((metricKey) => {
              const configHeader = {
                // $FlowFixMe - Flow says that configHeader is type 'mix' and it doesn't match to 'metric' type expected
                taxonomy: `${filter.key}:${filter.value}`,
                metricKey,
                metricGroupKey,
                name: undefined,
              };
              configHeader.name = getColumnLabel(
                // $FlowFixMe - Flow says that configHeader is type 'mix' and it doesn't match to 'metric' type expected
                configHeader,
                availableMetrics,
                true,
                true,
              );

              headersFinal.push(configHeader);
            });
          }
        });
      } else if (
        activeMetricValue.taxonomies &&
        Array.isArray(activeMetricValue.taxonomies)
      ) {
        activeMetricValue.taxonomies.forEach((taxonomy) => {
          if (
            activeMetricValue.metrics &&
            Array.isArray(activeMetricValue.metrics)
          ) {
            activeMetricValue.metrics.forEach((metricKey) => {
              const configHeader = {
                taxonomy,
                metricKey,
                metricGroupKey,
                name: undefined,
              };
              configHeader.name = getColumnLabel(
                // $FlowFixMe - Flow says that configHeader is type 'mix' and it doesnt match to 'metric' type expected
                configHeader,
                availableMetrics,
                true,
                true,
              );

              headersFinal.push(configHeader);
            });
          }
        });
      } else {
        if (
          activeMetricValue.metrics &&
          Array.isArray(activeMetricValue.metrics)
        ) {
          activeMetricValue.metrics.forEach((metricKey) => {
            const configHeader = {
              metricKey,
              metricGroupKey,
              name: undefined,
            };
            configHeader.name = getColumnLabel(
              // $FlowFixMe - Flow says that I can call getColumnLabel cause configHeader has type 'mixed'
              configHeader,
              availableMetrics,
              true,
              true,
            );

            headersFinal.push(configHeader);
          });
        }
      }
      return headersFinal;
    },
    [],
  );

  return [...defaultHeaders, ...metricsHeaders];
};

const findQueryResponse = ({
  breakdownBy,
  isAllLocations,
  excludeStaff,
  locationConfigList,
  period,
  header,
  queryResponseList,
}: {
  excludeStaff: boolean,
  period: QueryApiRequestModel.periodT,
  header: configHeaderT,
  locationConfigList: locationConfigT[],
  breakdownBy?: breakdownByEnum,
  queryResponseList: QueryResponseListModel.t,
  isAllLocations: boolean,
}): ?QueryResponseListModel.itemT => {
  let breakdownByDimensions;

  if (breakdownBy) {
    if (breakdownBy === BREAKDOWN_BY_DEFAULT) {
      if (isAllLocations) {
        breakdownByDimensions = undefined;
      } else {
        breakdownByDimensions = ['entity'];
      }
    } else if (breakdownBy === 'location') {
      breakdownByDimensions = ['entity'];
    } else {
      if (isAllLocations) {
        breakdownByDimensions = [breakdownBy];
      } else {
        breakdownByDimensions = ['entity', breakdownBy];
      }
    }
  } else {
    console.error('Missing breakdown');
  }

  const searchCriteria = {
    excludeStaff,
    locations: locationConfigList.map((l) => l.locationId),
    isAllLocations: false, // it may be all on the page, but not all in the org
    isComparisonActive: false,
    aggregation: 'day',
    period,
    breakdownByDimensions,
    ...header,
    taxonomies: header.taxonomy ? [header.taxonomy] : undefined,
  };

  const queryResponse = QueryResponseListModel.findOne(
    searchCriteria,
    queryResponseList,
  );

  return queryResponse;
};

const formatAges = (age: string) => {
  return age.replace('_', '-');
};

const getBodyRows = ({
  excludeStaff,
  period,
  headers,
  location,
  locationConfigList,
  breakdownBy,
  queryResponseList,
  isAllLocations,
}: {
  excludeStaff: boolean,
  period: QueryApiRequestModel.periodT,
  headers: configHeaderT[],
  location?: locationConfigT,
  locationConfigList: locationConfigT[],
  breakdownBy: breakdownByEnum,
  queryResponseList: QueryResponseListModel.t,
  isAllLocations: boolean,
}): string[][] => {
  const rows = [];

  if (breakdownBy !== 'total_only') {
    // for each value

    let breakdownValues = [];

    if (breakdownBy === 'age') {
      breakdownValues = [
        '0_15',
        '16_24',
        '25_34',
        '35_44',
        '45_54',
        '55_64',
        '65_100',
      ];
    } else if (breakdownBy === 'gender') {
      breakdownValues = ['male', 'female'];
    } else if (breakdownBy === 'role') {
      breakdownValues = ['staff', 'customer'];
    }

    breakdownValues.forEach((breakdownValue) => {
      rows.push(
        headers
          .map((header) => {
            if (!header.metricKey && !header.metricGroupKey) {
              if (header.name === LOCATIONS_COLUMN) {
                return location
                  ? location.locationName
                  : isAllLocations
                  ? 'All locations'
                  : DEFAULT_EMPTY_VALUE;
              } else if (header.name === breakdownBy) {
                // hack for backwards compatibility
                if (breakdownBy === 'age') {
                  return formatAges(breakdownValue);
                }
                return breakdownValue;
              }
              return header.name;
            } else {
              const queryResponse = findQueryResponse({
                excludeStaff,
                period,
                header,
                locationConfigList,
                breakdownBy,
                queryResponseList,
                isAllLocations,
              });

              if (queryResponse) {
                const { error, data } = queryResponse;
                if (error) {
                  return DEFAULT_EMPTY_VALUE;
                } else if (data) {
                    let splits = {};
                    if (isAllLocations) {
                      splits = QueryResponseModel.getSummaryByBreakdown({
                        response: data,
                        metricKey: header.metricKey,
                      });
                    } else {
                      splits = QueryResponseModel.getSummaryByBreakdown({
                        response: data,
                        metricKey: header.metricKey,
                        filterBy: {
                          location: location ? location.locationId : '',
                        },
                      });
                    }
                    if (
                      splits !== null &&
                      typeof splits === 'object' &&
                      typeof splits[breakdownBy] === 'object'
                    ) {
                      //$FlowFixMe: this is complaining about field names
                      return splits[breakdownBy][breakdownValue];
                    }

                  return DEFAULT_EMPTY_VALUE;
                }
              }
              return DEFAULT_EMPTY_VALUE;
            }
          })
          .map((cell) => (typeof cell === 'number' ? cell.toString(10) : cell)),
      );
    });
  } else {
    rows.push(
      headers
        .map((header) => {
          if (!header.metricKey && !header.metricGroupKey) {
            if (header.name === LOCATIONS_COLUMN) {
              return location
                ? location.locationName
                : isAllLocations
                ? 'All locations'
                : DEFAULT_EMPTY_VALUE;
            } else {
              return header.name;
            }
          } else {
            const queryResponse = findQueryResponse({
              excludeStaff,
              period,
              header,
              locationConfigList,
              breakdownBy,
              queryResponseList,
              isAllLocations,
            });

            if (queryResponse) {
              const { error, data } = queryResponse;
              if (error) {
                return DEFAULT_EMPTY_VALUE;
              } else if (data) {
                if (isAllLocations) {
                  const res = QueryResponseModel.getSummaryValue({
                    response: data,
                    metricKey: header.metricKey,
                  });
                  return res === null ? DEFAULT_EMPTY_VALUE : res;
                } else {
                  const res = QueryResponseModel.getSummaryValue({
                    response: data,
                    locationId: location ? location.locationId : '',
                    metricKey: header.metricKey,
                  });
                  return res === null ? DEFAULT_EMPTY_VALUE : res;
                }
              }
            }
            return DEFAULT_EMPTY_VALUE;
          }
        })
        .map((cell) => (typeof cell === 'number' ? cell.toString(10) : cell)),
    );
  }

  return rows;
};

export const getRows = (
  excludeStaff: boolean,
  period: QueryApiRequestModel.periodT,
  activeMetrics: ActiveMetricsModel.t,
  breakdownBy: breakdownByEnum,
  queryResponseList: QueryResponseListModel.t,
  filteredLocationIds: string[],
  filteredLocationsConfigList: locationConfigT[],
  availableMetrics: AvailableMetricsModel.t,
  configHeadersList: configHeaderT[],
): string[][] => {
  const allLocationsRows =
    filteredLocationIds.length > 1
      ? getBodyRows({
          excludeStaff,
          period,
          headers: configHeadersList,
          locationConfigList: filteredLocationsConfigList,
          breakdownBy,
          queryResponseList,
          isAllLocations: true,
        })
      : [];

  const headers = configHeadersList.map((header) => header.name);
  let bodyRows = [];

  filteredLocationsConfigList.forEach((location) => {
    bodyRows.push(
      ...getBodyRows({
        excludeStaff,
        period,
        headers: configHeadersList,
        location,
        locationConfigList: filteredLocationsConfigList,
        breakdownBy,
        queryResponseList,
        isAllLocations: false,
      }),
    );
  });

  const rows = [headers, ...bodyRows, ...allLocationsRows];
  return rows;
};

const DownloadOptionsSummaryBtn = ({
  excludeAgeAndGender,
  excludeStaff,
  period,
  disabled,
  filename,
  activeMetrics,
  queryResponseList,
  filteredLocationIds,
  locations,
  breakdownBy,
  availableMetrics,
}: Props) => {
  const filteredLocationsConfigList = getLocationConfigs(
    filteredLocationIds,
    locations,
  );

  const configHeadersList = getHeadersConfig(
    activeMetrics,
    breakdownBy,
    queryResponseList,
    availableMetrics,
  );

  const onDownloadChart = () => {
    const rows = getRows(
      excludeStaff,
      period,
      activeMetrics,
      breakdownBy,
      queryResponseList,
      filteredLocationIds,
      filteredLocationsConfigList,
      availableMetrics,
      configHeadersList,
    );

    let payload = '';
    rows.forEach((row) => {
      const sanitisedRow = row.map((column) => sanitiseString(column));
      const rowStr = sanitisedRow.join(',');
      payload += rowStr + '\r\n';
    });

    const fullFilename = `all_locations_summary_data-${filename}.csv`;
    download(payload, fullFilename, 'text/csv');
    trackEvent(DOWNLOAD_CSV_BUTTON);
  };

  return (
    <StyledButtonGroup>
      <Button
        data-automation-trigger="download-csv"
        disabled={disabled}
        onClick={onDownloadChart}
        icon="floppy-disk"
      >
        <Desktop>Export visible data (CSV)</Desktop>
        <Mobile>Export visible (CSV)</Mobile>
      </Button>
      {locations &&
      locations[0] &&
      locations[0].organisation &&
      locations[0].organisation === '5e71f0e962f84900270874b2' ? (
        <DownloadByDayButton
          locations={locations}
          queryResponseList={queryResponseList}
          activeMetrics={activeMetrics}
          filteredLocationIds={filteredLocationIds}
          disabled={disabled}
          filename={filename}
          availableMetrics={availableMetrics}
          period={period}
          filteredLocationsConfigList={filteredLocationsConfigList}
          excludeStaff={excludeStaff}
        />
      ) : (
        <DownloadByDayCSV
          locations={locations}
          queryResponseList={queryResponseList}
          activeMetrics={activeMetrics}
          filteredLocationIds={filteredLocationIds}
          disabled={disabled}
          availableMetrics={availableMetrics}
          period={period}
          filteredLocationsConfigList={filteredLocationsConfigList}
          excludeStaff={excludeStaff}
          excludeAgeAndGender={excludeAgeAndGender}
        />
      )}
    </StyledButtonGroup>
  );
};

export default DownloadOptionsSummaryBtn;
