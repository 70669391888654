import './polyfills';

import { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

import { logrocketInit } from '../services/logrocket';
import { sentryInit } from '../services/sentry';
import { googleAnalyticsInit } from '../services/ga';
import { splitInit } from '../services/split';
import { mixpanelInit } from '../services/mixpanel';
import { auth0Init } from '../services/auth0';
import { localDBInit } from '../models/database';
import { GOOGLE_MAPS_API_KEY } from '../config/vars';
import PreInitLoading from '../components/pages/pre-init-loading';

import '../styles/reset.css';

import store from './store';
import history from './history';
import Routes from './routes';
import { CloseRequestsOnLocationChange } from '../components/hook-wrapper';

// terrible, but it works
window.isDemoOrg = false;

logrocketInit();
sentryInit();
googleAnalyticsInit();
mixpanelInit();
const client = splitInit();

class Auth0Wrapper extends Component {
  state = { ready: false };

  async componentDidMount() {
    const params = new URL(document.location).searchParams;

    if (params.get('loginRedirect') !== 'false') {
      // Don't load Auth0 if asked not to
      // This is used by puppeteer/browserless to load the page without loading Auth0
      // It will then inject its own token into local storage

      const auth0Client = await auth0Init();
      this.setState({ auth0Client });

      if ((params.get('code') && params.get('state')) || params.get('error')) {
        try {
          await auth0Client.handleRedirectCallback();
          history.replace(window.location.pathname);
        } catch (error) {
          history.replace(window.location.pathname);
        }
      }
    }

    this.setState({ ready: true });
  }

  render() {
    const { ready } = this.state;
    const { children } = this.props;
    return ready ? children : <PreInitLoading />;
  }
}

class App extends Component {
  state = {
    splitReady: false,
  };

  async componentDidMount() {
    const ready = () => {
      this.setState({ splitReady: true });
    };

    client.on(client.Event.SDK_READY, ready);
    client.on(client.Event.SDK_READY_TIMED_OUT, ready);
    await localDBInit();
  }

  render() {
    return this.state.splitReady ? (
      <>
        <Provider store={store}>
          <Router history={history}>
            <CloseRequestsOnLocationChange />
            <Auth0Wrapper>
              <LoadScript
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                libraries={['visualization']}
              />
              <Routes />
            </Auth0Wrapper>
          </Router>
        </Provider>
      </>
    ) : (
      <>
        <PreInitLoading />
      </>
    );
  }
}

export default App;
