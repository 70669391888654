// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import { dashboardReports } from '../services/api';
import * as ReportModel from '../models/report';
import { errorToast } from '../utils/toaster';

export const fetch =
  (): FluxStandardAction<string, *> => async (dispatch, getState) => {
    try {
      dispatch({ type: ACTIONS.FETCH_REPORTS_REQUEST });

      const response = await dashboardReports.fetch();

      const payload = {
        dashboardReports: response.map((report) =>
          ReportModel.create({
            id: report.id,
            name: report.name,
            description: report.description,
            config: report.config,
            status: report.status,
            order: report.order,
          }),
        ),
      };

      dispatch({ type: ACTIONS.FETCH_REPORTS_SUCCESS, payload });
    } catch (error) {
      errorToast({ message: 'Error fetching reports.', timeout: 2500 });
      console.error(error);
      dispatch({ type: ACTIONS.FETCH_REPORTS_ERROR });
    }
  };
