// @flow

import createAuth0Client from '@auth0/auth0-spa-js';
import history from '../root/history';
import { errorToast } from '../utils/toaster';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../config/vars';
import { forIn, startsWith } from 'lodash';

let auth0Client;
export const IMPERSONATION_KEY = 'impersonationAuth0Id';

const setImpersonationId = () => {
  const params = new URL(window.document.location).searchParams;
  const auth0Id = params.get('impersonateId');

  if (auth0Id) {
    try {
      window.localStorage.setItem(IMPERSONATION_KEY, auth0Id);
      history.push(window.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }
};

export const getImpersonationId = () => {
  try {
    const impersonationId = window.localStorage.getItem(IMPERSONATION_KEY);
    return impersonationId;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const auth0Init = async () => {
  setImpersonationId();
  const impersonationId = getImpersonationId();

  const appState = impersonationId
    ? `impersonate:${impersonationId}`
    : undefined;

  // Delete the old user's Auth0 data from localStorage when impersonating
  // NOTE: this may change if Auth0 changes their approach/naming convention
  if (impersonationId) {
    forIn(window.localStorage, (value: string, objKey: string) => {
      if (startsWith(objKey, '@@auth0spajs@@')) {
        window.localStorage.removeItem(objKey);
      }
    });
  }

  auth0Client = await createAuth0Client({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    audience: 'https://api-core.auravision.ai',
    appState
  });

  // throw an alert if user is attempting to impersonate but cannot
  const user = await auth0Client.getUser();
  const tokenClaim = user && user['http://auravision.ai/impersonate'];
  if (
    (user && impersonationId && !tokenClaim) ||
    (user && impersonationId && tokenClaim !== impersonationId)
  ) {
    errorToast({
      message:
        'You attempted to impersonate a user, but do not have the ability to do so',
    });
  }
  return auth0Client;
};

export const getAuth0Client = () => auth0Client;

export const isBeingImpersonated = async () => {
  const user = await auth0Client.getUser();
  const tokenClaim = user && user['http://auravision.ai/impersonate'];
  return !!tokenClaim;
};
