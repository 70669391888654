// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as AuthActions from '../actions/auth';
import Authenticate from '../components/pages/authenticate';
import * as RecordingActions from '../actions/recording';
import { FETCHING_RECORDINGS } from '../constants/async-process-names';
import * as ReportActions from '../actions/reports';
import { FETCHING_REPORTS } from '../constants/async-process-names';

function mapStateToProps(state) {
  return {
    userRefreshCompleted: Selectors.userRefreshCompleted(state),
    userRefreshAuthFailure: Selectors.userRefreshAuthFailure(state),
    isUserNewlyCreated: Selectors.isUserNewlyCreated(state),
    user: Selectors.getUserModel(state),
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
    reports: Selectors.getReports(state),
    fetchingData: Selectors.asyncProcessHappening(state, FETCHING_RECORDINGS),
    fetchingReports: Selectors.asyncProcessHappening(state, FETCHING_REPORTS),
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      refreshUser: AuthActions.refreshUser,
      fetchRecordings: () => RecordingActions.fetch(ownProps.include),
      fetchReports: ReportActions.fetch,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
