import { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getAuth0Client } from '../../../services/auth0';
import * as ROUTES from '../../../constants/routes';
import PreInitLoading from '../pre-init-loading';

class LoginPage extends Component {
  state = { redirectToHome: false };

  async componentDidMount() {
    const auth0 = getAuth0Client();
    if (auth0) {
      const isAuthenticated = await auth0.isAuthenticated();

      if (isAuthenticated) {
        // The user has come directly here, but they are already authenticated so we send them to the home page.
        this.setState({ redirectToHome: true });
      } else {
        // The user has arrived here directly or via redirection, and they are not authenticated.

        // If the URL specifies a connection, then use it to initiate the login flow with the correct SSO provider.
        // Otherwise we don't need one for either `Username-Password-Authentication` Aura users or SSO IdP initiated logins where the user has already logged in.
        let connection;
        const params = new URL(document.location).searchParams;

        if (params.get('connection')) {
          connection = params.get('connection');
        }

        await auth0.loginWithRedirect({
          redirect_uri: window.location.origin,
          connection,
          showSettingUpLocations: params.get('showSettingUpLocations'),
          overrideDate: params.get('overrideDate'),
          impersonateId: params.get('impersonateId'),
          loginRedirect: params.get('loginRedirect'),
          emailView: params.get('emailView'),
        });
      }
    }
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to={{ pathname: ROUTES.HOME }} />;
    }

    return <PreInitLoading />;
  }
}

export default LoginPage;
