// @flow

import { Switch, Route } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

// helper containers
import Authenticate from '../containers/authenticate';
// page containers
import Summary from '../containers/summary';
import Logout from '../containers/logout';
import Heatmaps from '../containers/heatmaps';
import CameraPreview from '../containers/camera-preview';
import HeadcountPlanner from '../containers/headcount-planner';
import HomepageRedirect from '../containers/homepage-redirect';
import Explorer from '../containers/explorer';
import LiveOccupancyPage from '../containers/live-occupancy';
import SingleLocationLiveOccupancyPage from '../containers/single-location-live-occupancy';
import SettingsUsers from '../containers/settings-users';

import Reports from '../containers/reports';
import SingleReport from '../containers/single-report';
import Training from '../containers/training';
import ViewToken from '../containers/view-token';

// pages
import Login from '../components/pages/login';
import Page404 from '../components/pages/404';
import SettingsTools from '../components/pages/settings/tools';
import SettingsManageLocations from '../containers/manage-locations';
import SettingsManageLocation from '../containers/manage-single-location';
import SettingsManageRecording from '../containers/manage-single-recording';

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props: Object) => (
      <Authenticate>
        <Component {...props} />
      </Authenticate>
    )}
  />
);

const routes = () => (
  <Switch>
    <AuthenticatedRoute exact path={ROUTES.HOME} component={HomepageRedirect} />

    <Route
      path={ROUTES.EXPLORER}
      render={(props: Object) => (
        <Authenticate>
          <Explorer {...props} />
        </Authenticate>
      )}
    />

    <Route
      path={ROUTES.REPORTS}
      exact
      render={(props: Object) => (
        <Authenticate>
          <Reports {...props} />
        </Authenticate>
      )}
    />

    <Route
      path={ROUTES.SINGLE_REPORT}
      render={(props: Object) => (
        <Authenticate>
          <SingleReport {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={ROUTES.SUMMARY}
      render={(props: Object) => (
        <Authenticate>
          <Summary {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={ROUTES.SETTINGS_USERS}
      render={(props: Object) => (
        <Authenticate>
          <SettingsUsers {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={ROUTES.SETTINGS_TOOLS}
      render={(props: Object) => (
        <Authenticate>
          <SettingsTools {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={ROUTES.SETTINGS_MANAGE_LOCATIONS}
      render={(props: Object) => (
        <Authenticate>
          <SettingsManageLocations {...props} />
        </Authenticate>
      )}
    />

    <Route
      path={`${ROUTES.SETTINGS_MANAGE_SINGLE_LOCATION}/:locationId`}
      render={(props: Object) => (
        <Authenticate>
          <SettingsManageLocation {...props} />
        </Authenticate>
      )}
    />

    <Route
      path={`${ROUTES.SETTINGS_MANAGE_SINGLE_RECORDING}/:recordingId`}
      render={(props: Object) => (
        <Authenticate>
          <SettingsManageRecording {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={ROUTES.LIVE_OCC}
      render={(props: Object) => (
        <Authenticate>
          <LiveOccupancyPage {...props} />
        </Authenticate>
      )}
    />

    <Route
      exact
      path={`${ROUTES.LIVE_OCC}/:locationId`}
      render={(props: Object) => (
        <Authenticate>
          <SingleLocationLiveOccupancyPage {...props} />
        </Authenticate>
      )}
    />

    <Route exact path={ROUTES.HEATMAPS}>
      <Authenticate>
        <Heatmaps />
      </Authenticate>
    </Route>

    <Route exact path={ROUTES.CAMERA_PREVIEW}>
      <Authenticate>
        <CameraPreview />
      </Authenticate>
    </Route>

    <Route exact path={ROUTES.HEADCOUNT_PLANNER}>
      <Authenticate>
        <HeadcountPlanner />
      </Authenticate>
    </Route>

    <Route exact path={ROUTES.TRAINING}>
      <Authenticate>
        <Training />
      </Authenticate>
    </Route>

    <Route
      path={ROUTES.VIEW_TOKEN}
      render={(props: Object) => (
        <Authenticate>
          <ViewToken />
        </Authenticate>
      )}
    />

    <Route exact path={ROUTES.LOGIN} component={Login} />
    <Route exact path={ROUTES.LOGOUT} component={Logout} />
    <Route component={Page404} />
  </Switch>
);

export default routes;
