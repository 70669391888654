// @flow

import { Component, Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Navbar, NavbarGroup } from '@blueprintjs/core';

import { getAuth0Client, isBeingImpersonated } from '../../../services/auth0';
import * as ROUTES from '../../../constants/routes';
import Layout from '../../layouts/default-logged-in';
import DefaultLoggedIn from '../../layouts/default-logged-in';
import TopBarLoading from '../../top-bar-loading';
import NewUserSplashPage from '../new-user-splash';
import * as LocationModel from '../../../models/location';
import * as RecordingModel from '../../../models/recording';
import * as UserModel from '../../../models/user';
import * as ReportModel from '../../../models/report';
import { getTheme } from '../../../styles/theme';
import PreInitLoading from '../pre-init-loading';
import { initAPIConnection } from '../../../services/sql-api';
import { dropTables } from '../../../models/database';

const TopBar = styled(Navbar)`
  padding-left: 2em;
  padding-right: 2em;
`;

// override any blueprint styles here with theme variables
const BlueprintOverrides = styled.div`
  font-family: var(--fonts-default);

  .bp3-card {
    border-radius: var(--widget-border-radius);
  }

  .bp3-button:not([class*='bp3-intent-']) .bp3-icon {
    color: var(--button-icon-color);
  }

  .bp3-tab {
    outline: none;
  }

  .bp3-tab[aria-selected='true'] {
    color: var(--selected-text);
  }

  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: var(--selected-text);
  }

  .bp3-tag.bp3-intent-success {
    background-color: var(--ok);
  }

  .bp3-tag.bp3-intent-danger {
    background-color: var(--error);
  }
`;

const Wrapper = styled.div`
  background: var(--main-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type Props = {
  refreshUser: () => void,
  userRefreshCompleted: boolean,
  userRefreshAuthFailure: boolean,
  isUserNewlyCreated: boolean,
  children: any,
  user: UserModel.t,
  location: any, // BrowserHistory location
  recordings: Array<RecordingModel.t>,
  locations: Array<LocationModel.t>,
  fetchRecordings: () => void,
  fetchingData: boolean,
  reports: Array<ReportModel.t>,
  fetchReports: () => void,
};

type State = {
  auth0CheckCompleted: boolean,
  isAuthenticated: boolean,
  theme: Object,
  isReady: boolean,
  dataFetchStarted: boolean,
};

class Authenticate extends Component<Props, State> {
  state = {
    auth0CheckCompleted: false,
    isAuthenticated: false,
    theme: {},
    isReady: false,
    dataFetchStarted: false,
  };

  async componentDidMount() {
    const { recordings, locations, reports, fetchReports, fetchRecordings } =
      this.props;

    const getData = async () => {
      if (!!recordings.length && !!locations.length && !!reports.length) {
        // skip fetching anything if we already have data
        this.setState({ isReady: true });
      } else {
        if (!recordings.length || !locations.length)
          fetchRecordings();
        if (!reports.length) fetchReports();
        this.setState({ dataFetchStarted: true });
      }
    };
      const auth0Client = getAuth0Client();

      if (auth0Client) {
        const isAuthenticated = await auth0Client.isAuthenticated();
        this.setState({ auth0CheckCompleted: true, isAuthenticated });

        if (isAuthenticated) {
          const isImpersonating = await isBeingImpersonated();

          // delete the local cache when switching user
          if (isImpersonating) {
            console.log('This user is impersonating another user, so clearing the API cache');
            await dropTables();
          }

          // start the websocket as soon as we're authenticated
          initAPIConnection().then(() => getData());
          await this.props.refreshUser();
        }

        this.setState({ theme: await getTheme() });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { fetchingData } = this.props;
    const { dataFetchStarted, isReady } = this.state;

    if (dataFetchStarted && !isReady && !fetchingData) {
      this.setState({ isReady: true });
    }
  }

  render() {
    const {
      userRefreshCompleted,
      userRefreshAuthFailure,
      isUserNewlyCreated,
      children,
      location,
      refreshUser,
      user,
    } = this.props;

    if (this.state.theme !== null && this.state.theme.properties) {
      const body = document.querySelector('body');
      Object.entries(this.state.theme.properties).forEach(
        ([property, value]: [string, mixed]) => {
          if (body && body.style && typeof value === 'string') {
            body.style.setProperty(property, value);
          }
        },
      );
    }

    const { auth0CheckCompleted, isAuthenticated } = this.state;

    if (auth0CheckCompleted && !isAuthenticated)
      return (
        <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location }, search: window.location.search }} />
      );

    if (userRefreshAuthFailure)
      return (
        <Layout>
          <TopBar>
            <NavbarGroup>Authentication failure</NavbarGroup>
          </TopBar>
        </Layout>
      );

    if (auth0CheckCompleted && isAuthenticated && userRefreshCompleted) {
      if (isUserNewlyCreated)
        return <NewUserSplashPage user={user} refreshUser={refreshUser} />;
      return (
        <>
          {this.state.theme && Object.keys(this.state.theme).length ? (
            <ThemeProvider theme={this.state.theme}>
              <BlueprintOverrides>
                <Wrapper>
                  <Fragment>
                    {this.state.isReady ? (
                      children
                    ) : (
                      <DefaultLoggedIn>
                        <TopBarLoading />
                      </DefaultLoggedIn>
                    )}
                  </Fragment>
                </Wrapper>
              </BlueprintOverrides>
            </ThemeProvider>
          ) : (
            <PreInitLoading />
          )}
        </>
      );
    }

    // user refresh is still in progress....
    return (
      <>
        {this.state.theme && Object.keys(this.state.theme).length ? (
          <ThemeProvider theme={this.state.theme}>
            <BlueprintOverrides>
              <Layout>
                <TopBar>
                  <NavbarGroup>
                    <TopBarLoading />
                  </NavbarGroup>
                </TopBar>
              </Layout>
            </BlueprintOverrides>
          </ThemeProvider>
        ) : (
          <PreInitLoading />
        )}
      </>
    );
  }
}

export default Authenticate;
