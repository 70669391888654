import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Icon, Button } from '@blueprintjs/core';
import defaultLogo from '../../styles/logos/dashboard-logo.png';
import { variables } from '../../styles/variables';
import OrganisationSwitcher from './organisation-switcher';
import MenuItem from './desktop/menu-item';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { PoweredBy } from '../logos/powered-by';
import { version } from '../../../package.json';

const Wrapper = styled.section`
  width: ${variables.desktopNavbarWidth};
  background: var(--sidebar-background);
  flex: 0;
  order: -1;
  height: 100vh;
  overflow-y: auto;
  filter: ${(props) => (props.blur ? `blur(${props.blur})` : '')};
`;
const FlexScrollbars = styled(Scrollbars)`
  & > div {
    display: flex;
    flex-direction: column;
  }
`;
const Menu = styled.div`
  margin-top: 1em;
`;
const MenuDivider = styled.div`
  margin: 1em 1.5em;
  border-bottom: 1px solid var(--sidebar-divider);
`;
const UserSection = styled.section`
  margin: 0.5em;
  margin-top: 2.5em;
  padding: 1.5em 1em;
  color: var(--sidebar-text);
  border-top: 1px solid var(--sidebar-divider);
  display: flex;
  background: var(--sidebar-background);
  position: relative;
`;
const VersionSection = styled.section`
  color: var(--sidebar-text);
  text-align: center;
  opacity: 0.5;
  width: 100%;
  margin: auto 0 0.5rem;
`;
const UserTextSection = styled.div`
  flex: 1;
  margin-left: 1em;
`;
const UserName = styled.p`
  font-size: 0.9em;
  margin-bottom: 0.3em;
`;
const OrgName = styled.p`
  font-size: 0.85em;
`;
const LogoutButton = styled(Button)`
  color: var(--sidebar-text) !important;
  background: transparent !important;
  border: 1px solid var(--sidebar-text);

  & .bp3-button-text {
    color: var(--sidebar-text) !important;
  }
`;
const Logo = styled.img`
  width: 100%;
`;
const PoweredLogo = styled.div`
  display: block;
  margin: 1rem auto 2rem;
  width: 124px;
  aspect-ratio: 123 / 22;
`;

const DesktopSidebar = ({
  user = {},
  blur,
  logout,
  productMenuItems,
  location,
}) => {
  const theme = useContext(ThemeContext);

  let availableOrgs = [];
  if (user && user.availableOrgs) availableOrgs = user.availableOrgs;
  const showOrgSwitcher = availableOrgs.length > 1;

  const logo =
    theme && theme.logo && theme.logo.desktop
      ? `../assets/logos/${theme.logo.desktop}`
      : defaultLogo;

  return (
    <Wrapper blur={blur}>
      <FlexScrollbars
        renderThumbVertical={({ style, props }) => {
          const customStyle = {
            backgroundColor: 'rgb(255, 255, 255, 0.2)',
            borderRadius: '3px',
          };
          return <div {...props} style={{ ...style, ...customStyle }} />;
        }}
      >
        {theme && theme.logoLink ? (
          <a href={theme.logoLink}>
            <Logo src={logo} alt="Aura Vision Labs logo" />
          </a>
        ) : (
          <Logo src={logo} alt="Aura Vision Labs logo" />
        )}
        {theme && theme.cobranded === true && (
          <PoweredLogo>
            <PoweredBy />
          </PoweredLogo>
        )}
        <Menu>
          {productMenuItems.map((item, index) => {
            if (item.type === 'divider')
              return <MenuDivider key={`divider-${index}`} />;
            if (item.label === 'Admin')
              <MenuItem
                key={item.label}
                item={item}
                depth={3}
                location={location}
              />;
            return (
              <MenuItem key={item.label} item={item} location={location} />
            );
          })}
        </Menu>
        <UserSection>
          <Icon icon="user" />
          <UserTextSection>
            <UserName>{user.name}</UserName>
            <OrgName>{user.organisationName}</OrgName>
            <LogoutButton minimal onClick={logout}>
              Logout
            </LogoutButton>
            {showOrgSwitcher && <OrganisationSwitcher user={user} />}
          </UserTextSection>
        </UserSection>
        <VersionSection>v{version}</VersionSection>
      </FlexScrollbars>
    </Wrapper>
  );
};

export default DesktopSidebar;
